* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
:root {
  font-size: 10px;
  --black: #000000;
  --white: #ffffff;
}
html,
body {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
}
.section {
  width: 100%;
}
a {
  text-decoration: none;
  cursor: pointer;
}
.box {
  width: 100%;
  max-width: 1500px;
  padding: 0 10rem;
  margin: 0 auto;
}
.boogabera {
  width: 100%;
  height: 100vh;
}
.boogabera-content {
  width: 100%;
  height: 100%;
  position: relative;
}
.boogabera-content img {
  width: 100%;
  height: 100%;
  object-position: top;
  object-fit: cover;
}
.boogabera-content .logo {
  width: 135px;
  height: auto;
  object-fit: contain;
  position: absolute;
  top: 10%;
  left: 5%;
  transform: translate(0%, 15%);
}
.boogabera-center-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -30%);
}
.vesa-heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3rem;
}
.vesa-heading h2 {
  background: linear-gradient(
    205deg,
    #fff 7.89%,
    rgba(255, 255, 255, 0) 120.78%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  text-align: center;
  font-size: 6rem;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -1.28px;
  clip-path: inset(1px);
}
.vesa-heading h5 {
  background: linear-gradient(
    205deg,
    #fff 7.89%,
    rgba(255, 255, 255, 0) 120.78%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  font-size: 2.85rem;
  font-weight: 400;
  line-height: 114%;
  text-align: center;
  clip-path: inset(1px);
}
.vase-button {
  align-items: center;
  background: linear-gradient(
    229deg,
    #79aee7 7.02%,
    #4960a7 25%,
    #101012 61.26%
  );
  border-radius: 1.25rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-top: 5rem;
  padding: 2px;
  position: relative;
  overflow: hidden;
}
.vase-button:hover .blur {
  right: -15%;
  transition: 0.35s;
}
.blur {
  border-radius: 13rem;
  opacity: 0.8;
  background: rgba(134, 168, 230, 0.4);
  filter: blur(19px);
  width: 139.995px;
  height: 22.491px;
  flex-shrink: 0;
  position: absolute;
  top: 0;
  right: -30%;
  z-index: 4;
}
.vase-button span {
  align-items: center;
  background: linear-gradient(
    215deg,
    #3b4f75 4.01%,
    #1b253e 36.28%,
    #101012 64.94%
  );
  border-radius: 1.25rem;
  display: flex;
  justify-content: center;
  padding: 0.75rem 3.5rem;
  color: var(--white);
  text-align: center;
  font-size: 2.25rem;
  font-weight: 500;
  line-height: 142%;
  letter-spacing: -0.56px;
}

@media only screen and (max-width: 600px) {
  .boogabera-center-cont {
    width: 100%;
  }
  .vesa-heading h2 {
    font-size: 4rem;
    line-height: 130%;
  }
  .vesa-heading h5 {
    font-size: 2.15rem;
    line-height: 150%;
    text-align: center;
  }
  .boogabera-content .logo {
    width: 115px;
    top: 3%;
  }
}
